@import url('https://fonts.cdnfonts.com/css/earth-orbiter');
@import url('https://fonts.googleapis.com/css2?family=Vidaloka&display=swap');


:root {
    --color1: #ec579f;
    --color2: #F6C0DB;
    --color3: #be9592;
    --color4: #F4D1CF;
    --color5: #e6d88e;
    --heading-family: "Vidaloka";
    --body-family: "Roboto";
  }

html {
    height: 100%;
    scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
    min-height: 74vh;
}

a {
    color: inherit;
    text-decoration: none;
    -webkit-user-drag: none;


}

button {
    transition: transform 0.3s ease-in-out !important;
    -webkit-user-drag: none;
}

button:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
    -webkit-user-drag: none;
}

#footer {
    margin-top: 13vh;
    position: relative;
    bottom: 0;
    width: 100%;
    color: white;
}

#navlink {
    transition: none !important;
    transform: none;
}

#navlink:hover {
    transition: none !important;
    transform: none;
    text-decoration: underline;
    text-underline-offset: 4px;
}

#ornament {
    background-image: url('./assets/images/minified/ornament-circle.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

#ornament-sq {
    background-image: url('./assets/images/minified/ornament-sq.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.img-404{
    object-fit: contain;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.vcenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.gradient {
    background-image: -webkit-linear-gradient(var(--color1), var(--color2));
    box-shadow: none !important;
}

.gradient-reverse {
    background-image: -webkit-linear-gradient(var(--color2), var(--color1));
    box-shadow: none !important;
}

.signature {
    margin-top: 0.5rem;
    display: flex;
    justify-content: flex-end;
    font-family: 'Earth Orbiter';
    font-size: 1rem;
}

.swal2-styled {
    background-color: var(--color1) !important;
    border-color: var(--color1) !important;
    transition: transform 0.3s ease-in-out !important;
    box-shadow: none !important;
}
